import { ICellRendererParams } from "ag-grid-community";
import { IAccountCode } from "../../types/register";

export function accountCodeRenderer(params: ICellRendererParams & { template: HTMLTemplateElement }) {
  const accountCode: string | IAccountCode = params.value;
  const accountCodeName = accountCode ? (typeof accountCode === "object" ? accountCode.name : accountCode) : null;

  // If the row is grouped and the account code is missing, don't render anything
  if (params.node.group && !accountCodeName) {
    return null;
  }

  if (accountCodeName && accountCodeName !== "Uncoded") {
    const template = document.importNode(params.template.content, true);
    const div = template.querySelector("div");

    div.textContent = accountCodeName;

    return div;
  } else {
    return `<span class="text-gray-400 italic">Uncoded</span>`;
  }
}

export function tagRenderer(params: ICellRendererParams & { template: HTMLTemplateElement }) {
  const tags = params.value;

  // If the row is grouped, don't render anything
  if (params.node.group) {
    return null;
  }

  if (tags && tags.length > 0) {
    const parentEl = document.createElement("span");

    tags.forEach((tag) => {
      const template = document.importNode(params.template.content, true);
      const div = template.querySelector("div");

      div.textContent = tag.name;

      if (tag.color_bg) {
        div.style.backgroundColor = tag.color_bg;
        div.style.color = tag.color_fg;
      }

      parentEl.appendChild(div);
    });

    return parentEl;
  } else {
    return `<span class="text-gray-400 italic">Untagged</span>`;
  }
}
